body {
  background: rgb(0, 0, 18);
}

.App {
  /* Sticky footer */
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  text-align: center;
}

.Content {
  /* Sticky footer */
  flex: 1;
}

.footer {
  padding: 1.5rem;
}

.loader {
  margin: 2rem;
  color: '#F6F6F2';
}

.bar {
  height: 1rem;
  background-color: hsl(217, 71%, 53%);
}

.site-title span {
 margin: 0.3rem; 
 vertical-align: super;
}

.key-numbers span {
  margin: 0.3rem;
} 

.globe {
  cursor: move;
}

.nivo-container {
  height: 30rem;
  width: 100%;
}

.nivo-container-height-50 {
  height: 50rem;
  width: 100%;
}

.nivo-container-height-100 {
  height: 100rem;
  width: 100%;
}

.nivo-container-height-150 {
  height: 150rem;
  width: 100%;
}

.tabs.is-toggle a:hover {
  /* @fixme override bulma variables */
  color: black !important;
}

.section-home {
  padding: 3rem 0 0 0;
  background: rgb(0,0,0);
}

.section-home .container {
  padding-bottom: 1.5rem;
}

.section-text {
  text-align: left;
}

h1, h2, h3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

a {
  color: hsl(204, 86%, 53%);
}

a:hover {
  color: #F6F6F2;
}

@media screen and (min-width: 1025px) {
  .navbar-item.is-hoverable:hover .navbar-dropdown {
      display: block !important;
  }
  .navbar-item.is-hoverable:focus-within .navbar-dropdown {
      display: none;
  }
}
